@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.priorityLink {
  position: relative;

  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;
  transition: var(--transitionStyle);
  &::after {
    transition: var(--transitionStyle);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    height: 4px;
    background-color: var(--marketplaceColor);
    content: ' ';
  }
  &:hover {
    transition: var(--transitionStyle);
    color: var(--colorBlack);
    border-radius: 0;
    text-decoration: none;
    &::after {
      transition: var(--transitionStyle);
      width: 100%;
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
  &.highlight {
    color: var(--marketplaceColor);
    &::after {
      width: 100%;
    }
    &:hover {
      color: var(--marketplaceColorDark);
      &::after {
        width: 100%;
      }
    }
  }
}

.priorityLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
}
